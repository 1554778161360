<template>
  <!-- 订阅列表 -->
  <div id="app">
    <div class="center-tit">我的订阅<span>/My Account</span></div>
    <div class="containers">
      <div
        class="subscriptions"
        v-for="(subscription, index) in subscriptionList"
        :key="index"
      >
        <img :src="subscription.cover" alt="" class="banner" />
        <div class="content">
          <div class="title">{{ subscription.name }}</div>
          <div
            class="addtion"
            v-for="(addtion, sIndex) in subscription.services"
            :key="sIndex"
          >
            {{ addtion }}
          </div>
        </div>
        <div class="timeStatus">
          <div>创建订阅:{{ subscription.startTime }}</div>
          <div v-if="!subscription.cancel">
            下次订阅:{{ subscription.nextTime }}
          </div>
        </div>
        <div class="prices">${{ subscription.price }}/月</div>
        <div class="status">
          <el-button
            v-if="!subscription.cancel"
            class="updateSub"
            @click="updateSubscriptions(subscription.id)"
            >升级订阅</el-button
          >
          <br v-if="!subscription.cancel" />
          <el-button
            v-if="!subscription.cancel"
            class="cancelSub"
            @click="cancelSubscriptions(subscription.id)"
            >取消订阅</el-button
          >
          <span v-else class="canceled">订阅已取消</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { getSubscriptionList, cancelSubscriptions } from "@/service/subscribe";

export default {
  name: "subscription",
  data() {
    return {
      subscriptionList: [],
    };
  },
  computed: mapState(["userInfo"]),
  watch: {},
  created() {},
  mounted() {
    this.getSubscriptionList();
  },
  methods: {
    getSubscriptionList() {
      getSubscriptionList().then((res) => {
        if (res.success) {
          this.subscriptionList = res.result;
        }
      });
    },
    cancelSubscriptions(id) {
      this.$confirm("确认取消订阅？", "", {
        showClose: false,
        customClass: "msgBox",
      }).then(() => {
        cancelSubscriptions(id).then((res) => {
          if (res.success) {
            this.$message.success("取消订阅成功!");
            this.getSubscriptionList();
          }
        });
      });
    },
    updateSubscriptions(id) {
      this.$router.push(`/subscribe/choosePlan?updateId=${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.subscriptions {
  padding: 20px 40px;
  .banner {
    width: 160px;
    vertical-align: top;
  }
  .content {
    display: inline-block;
    margin-left: 16px;
    margin-top: 10px;
    .title {
      width: 250px;
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
      line-height: 14px;
      margin-bottom: 12px;
    }
    .addtion {
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #e59f3c;
      line-height: 18px;
    }
  }
  .timeStatus {
    width: 180px;
    display: inline-block;
    margin-left: 20px;
    vertical-align: top;
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    line-height: 45px;
  }
  .prices {
    width: 120px;
    display: inline-block;
    margin-left: 20px;
    font-size: 20px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #e02020;
    line-height: 30px;
    vertical-align: top;
    line-height: 90px;
  }
  .status {
    display: inline-block;
    margin-left: 40px;
    vertical-align: top;
    .updateSub {
      margin-top: 10px;
      border-radius: 18px;
      color: #fff;
      background: rgba(243, 187, 108, 1);
      padding: 7px 15px;
    }
    .cancelSub {
      margin-top: 20px;
      border-radius: 18px;
      color: #fff;
      background: rgba(204, 204, 204, 1);
      padding: 7px 15px;
    }
    .canceled {
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #999999;
      line-height: 90px;
      vertical-align: top;
    }
  }
}
</style>
<style lang="scss">
.msgBox {
  .el-message-box__message p {
    text-align: center !important;
  }
  .el-message-box__btns {
    text-align: center !important;
    padding: 15px 15px;
    .el-button.el-button--primary {
      background: linear-gradient(to left, #fd1d1d, #fcb045);
      border: none;
    }
  }
}
</style>

